<template>
  <div class="contact-wrapper">
    <div class="contact-container">
      <img
        :src="require('../assets/logo.png')"
        style="
          width: 300px;
          display: block;
          text-align: center;
          margin: auto;
          margin-bottom: 64px;
        "
      />

      <form @submit.prevent="handleSubmit" if="formID">
        <div style="display: flex; justify-content: space-between">
          <div style="width: 48%">
            <div style="margin-bottom: 24px">
              <label>Your Name</label>
              <input type="text" name="name" required />
            </div>

            <div style="margin-bottom: 24px">
              <label>Your Email</label>
              <input type="email" name="email" />
            </div>

            <div style="margin-bottom: 24px">
              <label>Your Phone Number</label>
              <vue-tel-input></vue-tel-input>
            </div>

            <div style="margin-bottom: 24px">
              <label>Your Nationality</label>
              <select name="nationality">
                <option v-for="item in countries" :key="item">
                  {{ item }}
                </option>
              </select>
            </div>
          </div>

          <div style="width: 48%">
            <label>Your Message</label>
            <textarea rows="17" cols="24" name="message" />
          </div>
        </div>

        <div class="checkboxes">
          <label style="margin-bottom: 16px">Service</label>
          <div class="checkboxes-container">
            <input
              name="service"
              type="checkbox"
              id="citizenship"
              value="citizenship"
            />
            <label for="citizenship">Citizenship</label>

            <span style="width: 24px" />

            <input
              name="service"
              type="checkbox"
              id="residency"
              value="residency"
            />
            <label for="residency">Residency</label>
          </div>
        </div>

        <div v-if="success_msg" style="margin-block: 32px; color: green">
          Your message was successfully sent
        </div>

        <div v-if="error_msg" style="margin-block: 32px; color: red">
          Failed, please try again later
        </div>

        <div class="flex-container buttons">
          <div>
            <button type="submit" class="submit-btn" :disabled="submitBtn">
              Send
            </button>
          </div>
          <div>
            <button type="reset" class="cancel-btn">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com"

export default {
  name: "ContactComponent",

  data() {
    return {
      submitBtn: false,
      success_msg: false,
      error_msg: false,
      countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Botswana",
        "Brazil",
        "Brunei",
        "Bulgaria",
        "Burkina Faso",
        "Burma",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Colombia",
        "Comoros",
        "Congo, Democratic Republic",
        "Congo, Republic of the",
        "Costa Rica",
        "Cote d'Ivoire",
        "Croatia",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "East Timor",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Fiji",
        "Finland",
        "France",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Greece",
        "Greenland",
        "Grenada",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, North",
        "Korea, South",
        "Kuwait",
        "Kyrgyzstan",
        "Laos",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macedonia",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Mauritania",
        "Mauritius",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Mongolia",
        "Morocco",
        "Monaco",
        "Mozambique",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Qatar",
        "Romania",
        "Russia",
        "Rwanda",
        "Samoa",
        "San Marino",
        " Sao Tome",
        "Saudi Arabia",
        "Senegal",
        "Serbia and Montenegro",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "Spain",
        "Sri Lanka",
        "Sudan",
        "Suriname",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syria",
        "Taiwan",
        "Tajikistan",
        "Tanzania",
        "Thailand",
        "Togo",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Yemen",
        "Zambia",
        "Zimbabwe"
      ]
    }
  },

  methods: {
    handleSubmit(e) {
      this.submitBtn = true

      emailjs
        .sendForm(
          "service_0hah6vb",
          "template_o4391yq",
          e.target,
          "Ik2cEGNEYYyFH6zau"
        )
        .then(() => {
          e.target.reset()
          this.success_msg = true
          this.error_msg = false
        })
        .catch(() => {
          this.success_msg = false
          this.error_msg = true
        })

      setTimeout(() => {
        this.success_msg = false
        this.error_msg = false
        this.submitBtn = false
      }, 5000)
    }
  }
}
</script>

<style>
textarea,
input[type="text"],
input[type="email"],
input[type="tel"],
select {
  background-color: rgb(244, 244, 244);
  width: 100%;
  border: none;
  border-radius: 4px;
  outline: 1px solid rgb(224, 224, 224);
  padding: 16px;
  font-size: 16px;
}
.contact-container {
  width: 100%;
  height: 100%;
  padding: 64px;
  max-width: 800px;
  margin: auto;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.flex-container div {
  width: 48%;
  margin-bottom: 24px;
}

h1 {
  text-align: center;
  margin-bottom: 48px;
  font-size: 2.6em;
}

label {
  display: inline-block;
  margin-bottom: 8px;
}

.checkboxes {
  margin-top: 8px;
}
.checkboxes-container {
  display: flex;
  align-items: center;
}
.checkboxes-container label {
  margin-bottom: 0px;
  padding-inline: 8px;
  cursor: pointer;
}
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.buttons {
  margin-top: 64px;
}

button {
  font-size: 16px;
}

.submit-btn {
  cursor: pointer;
  background-color: #0a436b;
  color: #fff;
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 16px;
  font-weight: bold;
  transition: all 0.3s;
}
.submit-btn:hover {
  transition: all 0.3s;
  background-color: #08385a;
}

.cancel-btn {
  cursor: pointer;
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 16px;
  font-weight: bold;
  color: #0a436b;
  transition: all 0.3s;
}
.cancel-btn:hover {
  transition: all 0.3s;
  background-color: rgb(218, 218, 218);
}
</style>
