<template>
  <div id="app">
    <ContactComponent />
  </div>
</template>

<script>
import ContactComponent from "./components/ContactComponent.vue"
export default {
  name: "App",
  components: { ContactComponent }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.vue-tel-input {
  border-radius: 4px !important;
  display: flex !important;
  border: none !important;
  text-align: left !important;
  box-shadow: none !important;
}

.vti__dropdown:hover {
  background-color: rgba(255, 0, 0, 0) !important;
}

.vti__input::placeholder {
  opacity: 0 !important;
}

:root {
  /* background-color: #0a436b; */
  scrollbar-color: #0a436b white; /* thumb and track color */
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-thumb {
  background-color: #0a436b;
}
::-moz-selection {
  color: #0a436b;
  background: #0a436b;
}
::selection {
  color: white;
  background: #0a436b;
}
@media (max-width: 768px) {
  :root {
    scrollbar-color: #0a436b white; /* thumb and track color */
    scrollbar-width: thin;
  }
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 6spx;
    background-color: #0a436b;
  }
}
@media (max-width: 625px) {
  :root {
    scrollbar-width: none;
  }
  ::-webkit-scrollbar {
    width: 2px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2px;
  }
}
</style>
