import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.use(VueTelInput);
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
